html {
    height: 100%;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: visible;
    background: #f9f9f9;
}
select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}
input[type='file'] {
    width: 100%;
}
.leaflet-container {
    height: 78vh;
    width: 100%;
}
/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
    > iframe[style*='2147483647']:not(
        [id='webpack-dev-server-client-overlay']
    ) {
    display: none;
}
